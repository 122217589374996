// import React from "react"
import React from 'react';

import { graphql } from 'gatsby'
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from "react-bootstrap";
import Layout from "../../../components/Layout";
import { GatsbyImage } from "gatsby-plugin-image"
import { Parallax } from 'react-parallax';

export default function Home({ data }) {
  const paralaxInlineStyle = {
    // background: '#fff',
    color: 'white',
    left: '50%',
    top: '50%',
    position: 'absolute',
    padding: '20px',
    transform: 'translate(-50%, -50%)'
  }
  return (

    <div>
      <Layout title="home">

        <Container className="pt-6">
          <section id="info">
            <div className="row">
              <div className="col-md-8">
                <a href="/">
                <GatsbyImage image={data.logoimage.childImageSharp.gatsbyImageData} alt="shanti-aid" />
                </a>






              </div>

              <div className="col-md-4 align-self-center text-center ">

                <h1 className="mt-4">December 28th </h1>

                <h2>2021 5K Walk </h2>
                <h2>The Charity and friends walked to lose Christmas Calories and Pile on Pounds for Shanti Aid</h2>






              </div>




            </div>



          </section>
        </Container>


        <Container>
          <section id="home-icons" className="py-5">
            <div className="row">
              <div className="col-md-1 mb-1 text-center"></div>
              <div className="col-md-10 mb-1 text-center">

             

              <h1>We raised over £2000 !</h1>
              <h2>Our route was round Birmingham City Centre</h2>
                <h2>We met at House of Fraser Birmingham</h2>
                
                <h3>Others did 5K walks at the same time.</h3>
                
                <GatsbyImage image={data.route.childImageSharp.gatsbyImageData} alt="5k route" />





              </div>
              <div className="col-md-1 mb-1 text-center"></div>
            </div>
          </section>
        </Container>


        <Container>

            <div className="ml-5 col-md-12 d-flex justify-content-center">
              <a href="https://www.gofundme.com/f/shanti-aid-education-for-women/donate?utm_source=widget&utm_medium=referral&utm_campaign=p_cp+share-sheet" target="_blank" rel="noreferrer">
                <GatsbyImage image={data.gofundme.childImageSharp.gatsbyImageData} alt="gofundme" />
              </a>
            </div>


            <div className="ml-5 col-md-12 d-flex justify-content-center">
              <a href="https://smile.amazon.co.uk/ch/1194862-0" target="_blank" rel="noreferrer">
                <GatsbyImage image={data.smile.childImageSharp.gatsbyImageData} alt="amazon smile" />
              </a>
            </div>



            

        </Container>



        <Container>
          <section id="home-icons" className="py-5">
            <div className="row">
              <div className="col-md-1 mb-1 text-center"></div>
              <div className="col-md-10 mb-1 text-center">
                <p><a href="tel:07765706066"><i role="button" aria-label="button" className="fas fa-phone fa-3x mb-2 text-dark"></i></a>
                  Our telephone number : <a className="text-danger" href="tel:03001021561">0300 102 1561</a></p>
                <p>In case of Emergency call <a className="text-danger" href="tel:999">999</a> from a landline</p>
                <p>or <a className="text-danger" href="tel:112">112</a> from Mobile.</p>
                <p>Email: <a className="nounderline text-dark" href="mailto:admin@shanti-aid.org">admin@shanti-aid.org</a></p>
                <p>Our opening hours are Monday - Friday 9am-5pm.</p>
              </div>
              <div className="col-md-1 mb-1 text-center"></div>
            </div>
          </section>
        </Container>
        <Container>
          <section id="home-icons" className="py-5">
            <div className="row">
              <div className="col-md-4 mb-1 text-center"></div>
              <div className="col-md-1 mb-1 text-center">
                <a className="text-dark" target="_blank" rel="noreferrer" href="https://twitter.com/ShantiAid"><i role="button" aria-label="button" className="fab fa-twitter-square fa-4x mb-2"></i></a>

              </div>
              <div className="col-md-1 mb-1 text-center">
                <a className="text-dark" target="_blank" rel="noreferrer" href="https://www.facebook.com/shanti.aid.3"><i role="button" aria-label="button" className="fab fa-facebook-square fa-4x mb-2"></i></a>
              </div>
              <div className="col-md-1 mb-1 text-center">
                <a className="text-dark" target="_blank" rel="noreferrer" href="https://www.instagram.com/shantiaid/"><i role="button" aria-label="button" className="fab fa-instagram fa-4x mb-2"></i></a>
              </div>
              <div className="col-md-1 mb-1 text-center">
                <a className="text-dark" target="_blank" rel="noreferrer" href="mailto:admin@shanti-aid.org"><i role="button" aria-label="button" className="fas fa-envelope-square fa-4x mb-2"></i></a>
              </div>
              <div className="col-md-3 mb-1 text-center"></div>
            </div>
          </section>
        </Container>
        


        <Parallax bgImage="winding-road.jpg" strength={500}>

          <div style={{ height: 500 }}>
            <div style={paralaxInlineStyle}>
              <h1 className="display-4 mr-2">Not all paths are complicated</h1>
              <h1 className="display-4 mr-2">Don't think there is no help</h1>
            </div>
          </div>
        </Parallax>



        
        

      </Layout>
    </div>

  )

}







export const query = graphql`
  {
    eflogo: file(relativePath: { eq: "ef-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 600)
      }
    },
    eflogosm: file(relativePath: { eq: "ef-logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 400)
      }
    },
    gofundme: file(relativePath: { eq: "gofundme.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    },    
    smile: file(relativePath: { eq: "amazon-smile-uk.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 300)
      }
    },    
    logoimage: file(relativePath: { eq: "shanti_aid.png" }) {
      childImageSharp {
        gatsbyImageData(width: 900)
      }
    },
    handimage: file(relativePath: { eq: "open-hand.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    }      
    ,
    strongimage: file(relativePath: { eq: "strong.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    } 
    ,
    sunsetimage: file(relativePath: { eq: "sunset.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    },
    roadimage: file(relativePath: { eq: "winding-road.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1600)
      }
    },
    route: file(relativePath: { eq: "Geo-Tracker-2021-12-28-13-52-04.png" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    }
    
    


  }
`


//C:\Users\jon\projects\gatsby\gatsby-shanti-aid\src\images\Geo-Tracker-2021-12-28-13-52-04.png